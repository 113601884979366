import React, { useEffect, useState } from "react";
import { Form, Input, Button, Modal, Spin, Slider, Typography } from "antd";
import { instance } from "utils/axios";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const QuestionForm = ({ visible, onClose, onSubmit, quiz,loading }) => {
  const [form] = Form.useForm();

  // const [loading, setLoading] = useState(false);
  const renderQuestionField = (question) => {
    switch (question.qType) {
      case "range":
        return (
          <Form.Item
            key={question.id}
            label={question.question}
            name={`question_${question.id}`}
            rules={[{ required: true, message: "Хариултаа оруулна уу!" }]}
          >
          
              <Slider
                min={question.minValue || 0}
                max={question.maxValue || 10}
                className="mt-8"
                tooltipVisible
                marks={{
                  [question.minValue]: (
                    <FrownOutlined style={{ color: "red" }} />
                  ),
                  [question.maxValue]: (
                    <SmileOutlined style={{ color: "green" }} />
                  ),
                }}
              />
          
          </Form.Item>
        );
      case "short-answer":
        return (
          <Form.Item
            key={question.id}
            label={question.question}
            name={`question_${question.id}`}
            rules={[{ required: true, message: "Хариултаа оруулна уу!" }]}
          >
            <Input placeholder="Өөрийн хариултаа бичнэ үү..." />
          </Form.Item>
        );
      case "paragraph":
        return (
          <Form.Item
            key={question.id}
            label={question.question}
            name={`question_${question.id}`}
            rules={[{ required: true, message: "Хариултаа оруулна уу!" }]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Өөрийн хариултаа бичнэ үү..."
            />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  const handleFinish = (values) => {

     const question_answers = Object.keys(values).map((key) => {
       const questionId = key.split("_")[1];
       const question = quiz.questions.find(
         (q) => q.id === parseInt(questionId, 10)
       );

       return {
         questionId: parseInt(questionId, 10),
         answer:
           question.qType === "range" || question.qType === "number"
             ? null
             : values[key],
         numberValue:
           question.qType === "range" || question.qType === "number"
             ? values[key]
             : null,
         answerId: null,
         dailyQuestion: question.dailyChange == true ? question.question : null,
       };
     });

     const formattedResponse = {
       quizId: quiz.id,
       question_answers: question_answers,
     };

     console.log("Formatted Response:", formattedResponse);
     onSubmit(formattedResponse);
     form.resetFields();
     onClose();
   

  };

  return (
    <Modal
      // title="Өдрийн асуулт"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      {loading ? (
        <Spin size="large" />
      ) : (
        <Form form={form} onFinish={handleFinish} layout="vertical">
          {quiz && (
            <>
              <Title level={3}>{quiz.title}</Title>
              <div
                className="border p-4 rounded-md mb-3"
                dangerouslySetInnerHTML={{ __html: quiz?.description }}
              ></div>
            </>
          )}
          {quiz?.questions.map((question) => renderQuestionField(question))}
          <Button type="primary" htmlType="submit" block>
            Илгээх
          </Button>
        </Form>
      )}
    </Modal>
  );
};

export default QuestionForm;
