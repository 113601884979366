import React from "react";
import actions from "./actions";
import reducer from "./reducer";
import ls from "utils/ls";

const AuthContext = React.createContext();

const initialState = {
  loading: true,
  studentInfo: null,
  token: null,
  tabIndexSetting: 0,
  sidebarWidth: "250px",
  sidebarCollapsed: false,
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const action = React.useMemo(() => actions(dispatch), []);

  React.useLayoutEffect(() => {
    const bootstrapAsync = async () => {
      let accessToken;
      try {
        var studentInfo = ls.get("studentInfo");

        var tabIndex = ls.get("tabIndexSetting");
        accessToken = studentInfo && studentInfo.token;
        dispatch({
          type: "RESTORE_TOKEN",
          token: accessToken,
          studentInfo: studentInfo,
        });
        if (tabIndex) {
          dispatch({ type: "CHANGE_TAB_INDEX_SETTING", index: tabIndex });
        }
      } catch (e) {}
    };

    bootstrapAsync();
  }, []);

  return (
    <AuthContext.Provider value={{ state, action }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
