import React from 'react'
import Header from './Header'
import { Outlet } from 'react-router-dom'

function Layout() {
  return (
    <div className='relative flex flex-col min-h-screen'>
      <Header/>
      <div id='body' className='pt-[60px] px-7'>
        <Outlet/>
      </div>
    </div>
  )
}

export default Layout
