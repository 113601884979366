import "./SignaturePad.css"; // Optional: for custom styles
import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Input } from "antd";
import SignatureCanvas from "react-signature-canvas";
import { instance } from "utils/axios";
const SignaturePad = ({ editData, open, handleCloseModal }) => {
  const [canvasReady, setCanvasReady] = useState(false);
  const sigCanvas = useRef(null);
  const [form] = Form.useForm();
  useEffect(() => {
     if (editData) {
       form.setFieldsValue({
         lastName: editData.lastName,
         firstName: editData.firstName,
         mobile: editData.mobile,
         // Add other fields if needed
       });
     }
  }, [editData, form]);
  useEffect(() => {
    if (open) {
     
      const timeoutId = setTimeout(() => {
        setCanvasReady(true);
        if (sigCanvas.current) {
          sigCanvas.current.clear(); 
        }
      }, 300); 

      return () => {
        clearTimeout(timeoutId);
        setCanvasReady(false); 
      };
    }
  }, [open]);

  const handleClear = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };

  const handleFormSubmit = (values) => {
    if (sigCanvas.current && canvasReady && !sigCanvas.current.isEmpty()) {
      const canvas = sigCanvas.current.getCanvas();
      const dataURL = canvas.toDataURL("image/png");
      const data = {
        studentId:editData.studentId,
        tuitionId:editData.tuitionId,
        studentId:editData.studentId,
        tuitionId:editData.tuitionId,
        lastName: values.lastName,
        firstName: values.firstName,
        mobile: values.mobile,
        signature:dataURL,
      }
      instance({
        method: "post",
        url: `/student/contract/create`,
        data: data,
      })
        .then(() => {
          handleCloseModal(); 
        })
        .catch((err) => {
          console.log(err)
          // if (err.response.status == 403) {
          //   modal.warning({
          //     title: "Анхаарна уу !",
          //     content: "Хэрэглэгчийн эрх хүрэхгүй байна",
          //   });
          // }
        });
     
    } else {
      console.log("Signature is empty");
     
    }
  };
  return (
    <Modal
      title="Баталгаажуулах"
      open={open}
      okText="Баталгаажуулах"
       cancelText="Хаах"
      onCancel={handleCloseModal}
      onOk={form.submit} // Handle save action directly
      confirmLoading={false}
    >
      {canvasReady && (
        <Form layout="vertical" onFinish={handleFormSubmit} form={form}  editData={editData}>
          <Form.Item
            name="lastName"
            label="Овог"
            rules={[{ required: true, message: "Овог оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="firstName"
            label="Нэр"
            rules={[{ required: true, message: "Нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="mobile"
            label="Утас"
            rules={[{ required: true, message: "Утас оруулна уу!" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="signature"
            label="Гарын үсэг"
          >
            <SignatureCanvas
              ref={sigCanvas}
              penColor="black"
              canvasProps={{
                width: 500,
                height: 200,
                className: "signatureCanvas",
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button onClick={handleClear}>Цэвэрлэх</Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default SignaturePad;
