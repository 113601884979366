import React, { useEffect, useState, useContext, useRef } from "react";

import { Button, Space, List, Table, Tag } from "antd";
import { instance } from "utils/axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "contexts";
import StudentStatus from "../Home/StudentStatus";

function PaymentStatus() {
  const { state } = useContext(AuthContext);
  const name = state.studentInfo?.profile?.firstName;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/guardian/students`,
    })
      .then((res) => {
        setStudents(res.data);
        // setData(res.data);
      })
      .catch((err) => {
        console.log("here");
      })
      .then(() => setLoading(false));
  };
  const handleDecision = (studentId, decision) => {
    setLoading(true);
    instance({
      method: "put",
      url: `/guardian/student/decision`,
      data: {
        decision,
        studentId,
      },
    })
      .then((res) => {
        fetchData();
        // setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  return (
    // <div className="topbar w-full h-16 bg-primary2 flex justify-between items-center px-4">
    <div className="flex flex-col items-center h-screen relative">
      <div className="mt-16 w-full flex justify-center">
        <div className="m-8 w-4/5">
          <div className=" gap-4 mx-auto py-20 md:py-0 mb-8">
            <div className="text-[20pt] md:text-[20pt] xl:text-[30pt] font-extrabold text-primary leading-[1.38]">
              Сайн байна уу {name},
            </div>
          </div>
          {students.map((student) => {
            return (
              <div
                key={student.id}
                className="gap-4 mx-auto py-20 md:py-0 mb-8"
              >
                {
                  student.permission ? (
                    <div>
                      <div className=" gap-4 mx-auto py-20 md:py-0 mb-8">
                        Та хүүхдийнхээ Томүжин Алтернатив сургуульд 2024-2025
                        оны хичээлийн жилд үргэлжлүүлэн суралцахтай холбоотой
                        сургалтын төлбөрийн мэдээлэл, суудал баталгаажуулалт
                        болон гэрээний явцтай танилцана уу.
                      </div>
                      <StudentStatus
                        data={student.student_program_steps.sort(
                          (a, b) => a.id - b.id
                        )}
                      />
                    </div>
                  ) : (
                    <>
                      Таны хүүхдийн 2024-2025 оны хичээлийн жилд үргэлжлүүлэн
                      суралцах эрх одоогоор үүсээгүй байгаа тул суудал
                      баталгаажуулалт хийх боломжгүйг мэдэгдье. Сургалтын
                      гэрээний төлбөрийн ноцтой зөрчил, сурагчдын баримтлах
                      дотоод журмын зөрчил, бичгэн сануулга, хичээлийн ирц,
                      оролцооны хувь бага байсан гэсэн эдгээр шалтгааны аль нэг
                      нь сурагчийн датанд байгаа тул эрх үүсээгүй байна. Эрхээ
                      үүсгэхийн тулд сургалтын албатай холбогдоорой.
                    </>
                  )
                  // : (
                  //   <div className="">
                  //     <div>
                  //       {student.firstName} 2024-2025 оны хичээлийн жил суралцах
                  //       сонголтоо хийнэ үү.
                  //     </div>
                  //     <div className="grid grid-cols-12 gap-12 mt-4">
                  //       <Button
                  //         type="primary"
                  //         className=" col-span-6 md:col-span-6  gap-12"
                  //         style={{ height: 80, fontSize: 40 }}
                  //         onClick={() => {
                  //           handleDecision(student.id, "Тийм");
                  //           // navigate("/decision");
                  //         }}
                  //       >
                  //         Суралцана
                  //       </Button>
                  //       <Button
                  //         className="col-span-6 md:col-span-6  gap-6"
                  //         style={{ height: 80, fontSize: 40 }}
                  //         onClick={() => {
                  //           handleDecision(student.id, "Үгүй");
                  //           // navigate("/decision");
                  //         }}
                  //       >
                  //         Шилжинэ
                  //       </Button>
                  //     </div>
                  //   </div>
                  // )
                }
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PaymentStatus;
