import { AuthProvider } from "contexts";
import { StyleProvider } from '@ant-design/cssinjs';
import StyleConfigProvider from "StyleConfigProvider";
import router from 'routes'
import { RouterProvider, ScrollRestoration } from "react-router-dom";
import AxiosComponent from 'utils/axios'

function App() {
  return (
    <StyleProvider hashPriority='high'>
      <StyleConfigProvider>
        <AuthProvider>
          <AxiosComponent>
            <RouterProvider router={router}>
              <ScrollRestoration />
            </RouterProvider>
          </AxiosComponent>
        </AuthProvider>
      </StyleConfigProvider>
    </StyleProvider>
  );
}

export default App;
