import React, { useEffect, useState, useContext, useRef } from "react";

import { Button, Space, List, Table, Tag,Spin,DatePicker } from "antd";
import { instance } from "utils/axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "contexts";
import dayjs from "dayjs";
function ParentHome() {
 const navigate = useNavigate();
 const { state } = useContext(AuthContext);
 const parent = state.studentInfo?.profile;
 const [data, setAttendance] = useState([]);
  const [date, setDate] = useState(dayjs());
 const [loading, setLoading] = useState(false);
 useEffect(() => {
   fetchData();
 }, [date]);
 const fetchData = () => {
   setLoading(true);
   instance({
     method: "get",
     url: `/guardian/attendance?date=${date}`,
   })
     .then((res) => {
       console.log(res.data);
       setAttendance(res.data);
     })
     .catch((err) => {
       console.log("here");
     })
     .then(() => setLoading(false));
  //  instance({
  //    method: "get",
  //    url: `/student/profile`,
  //  })
  //    .then((res) => {
  //      console.log(res.data);
  //      setProfile(res.data);
  //    })
  //    .catch((err) => {
  //      console.log("here");
  //    })
  //    .then(() => setLoading(false));
 };




 const columns = [
   { title: "Огноо", dataIndex: "attendanceTime", key: "date" },
   {
     title: "Төлөв",
     dataIndex: "status",
     key: "status",
     render: (status) => {
       let color;
       switch (status) {
         case "Ирсэн":
           color = "green";
           break;
         case "Хоцорсон":
           color = "warning";
           break;
         case "Чөлөөтэй":
           color = "blue";
           break;
         case "Тасалсан":
           color = "red";
           break;
         default:
           color = "default";
       }
       return <Tag color={color}>{status}</Tag>;
     },
   },
   // {
   //   title: "Асуулга",
   //   dataIndex: "dailyQuiz",
   //   key: "dailyQuiz",
   //   render: (value) => {
   //     return <Tag color={value ? "success" : "success"}>{value}</Tag>;
   //   },
   // },
 ];

return (
  <div className="student-home-container  items-center h-screen relative">
    <div className="my-4 flex justify-center gap-2">
      <DatePicker value={date} onChange={(e) => setDate(e)} picker="month" />
      <Button onClick={fetchData}>Refresh</Button>
      {/* <Button type="primary" onClick={() => fetchQuizDetails()}>
      Асуулга
    </Button> */}
    </div>
    {loading ? (
      <Spin size="large" />
    ) : (
      <div className="flex flex-wrap justify-center gap-4">
        {data?.map((item) => (
          <div
            key={item.id}
            className="w-full lg:w-1/3 flex justify-center"
          >
            <div className="m-8 w-full">
              <div className="student-info text-center bg-white p-4 rounded-lg shadow-lg">
                <div className="flex items-center justify-center space-x-4">
                  <div className="bg-primary p-2">
                    <img
                      src={item?.house?.googleLink}
                      alt="Profile"
                      className="h-8 w-8 object-cover rounded-full"
                    />
                  </div>
                  <div>
                    <h3 className="text-2xl font-bold inline-block">
                      {item.firstName}
                    </h3>
                    <p className="text-lg font-semibold">id: {item.code}</p>
                  </div>
                </div>
              </div>
              <div className="attendance-list bg-white p-4 rounded-lg shadow-lg mt-4">
                <h3 className="text-xl font-semibold mb-4">
                  Ирсэн цаг
                </h3> 
                <Table
                  dataSource={item.attendances}
                  columns={columns}
                  rowKey="id"
                  pagination={false}
                  className="w-full"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
);

}

export default ParentHome;
