import React, { useEffect, useState, useContext, useRef } from "react";

import { Button, Space, List, Table, Tag } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useWindowSize } from "react-use";
// import axios from "axios";
import { instance } from "utils/axios";
import { useNavigate } from "react-router-dom";
import logo from "assets/images/logo.jpg";
import { AuthContext } from "contexts";

function Enroll() {
  const { state } = useContext(AuthContext);
  const name = state.studentInfo?.profile?.firstName;
  const { width, height } = useWindowSize();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    instance({
      method: "get",
      url: `/applicant/status`,
    })
      .then((res) => {
       
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  }, []);
  const columns = [
    {
      title: "Гарчиг",
      dataIndex: "title",
      key: "title",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Тайлбар",
      dataIndex: "desc",
      key: "desc",
    },

    {
      title: "Төлөв",
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          color={
            status == "Accept"
              ? "success"
              : status == "Reject"
              ? "error"
              : "warning"
          }
          key={status}
        >
          {status ?? "Хүлээгдэж байна"}
        </Tag>
      ),
    },
    {
      title: "Хариу",
      key: "action",
      render: (_, record) => (
        <Button
          className="justify-center"
          onClick={() => {
            window.open("/decision", "_blank");
            // navigate("/decision");
          }}
        >
          <EyeOutlined style={{display: 'flex'}}/>
        </Button>
      ),
      width: 100,
      fixed: "right",
    },
  ];

  return (
    // <div className="topbar w-full h-16 bg-primary2 flex justify-between items-center px-4">
    <div className="flex flex-col items-center h-screen relative">
      <div className="mt-16 w-full flex justify-center">
        <div className="m-8 w-4/5">
          <div className=" gap-4 mx-auto py-20 md:py-0 mb-8">
            <div className="text-[20pt] md:text-[20pt] xl:text-[30pt] font-extrabold text-primary leading-[1.38]">
              Сайн уу {name},
            </div>
            <div>2024-2025 оны хичээлийн жил суралцах сонголтоо хийнэ үү.</div>
          </div>
          <div className="gap-4 mx-auto py-20 md:py-0 mb-8">
            <div className="grid grid-cols-12 gap-12">
              <Button
                type="primary"
                className="col-span-6 md:col-span-6  gap-12"
                style={{ height: 100, fontSize: 40 }}
                onClick={() => {
                  window.open("/qpay", "_blank");
                  // navigate("/decision");
                }}
              >
                Суралцана
              </Button>
              <Button
                className="col-span-6 md:col-span-6  gap-6"
                style={{ height: 100, fontSize: 40 }}
              >
                Шилжинэ
              </Button>
            </div>
          </div>

          {/* <Table
           
            columns={columns}
            dataSource={data}
            pagination={false}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default Enroll;
