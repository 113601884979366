import React, { useContext } from "react";
import { AuthContext } from "contexts";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectRoute = ({ children, routeItem }) => {
  const { state } = useContext(AuthContext);
  const location = useLocation();
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { studentInfo: state });
    }
    return child;
  });
  if (!state.token && !state.loading) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return <Outlet />;
  }
};

export default ProtectRoute;
