import React, { useEffect, useState, useContext, useRef } from "react";

import { Button, Space, List, Table, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "contexts";

function StudentStatus({data}) {
  const { state } = useContext(AuthContext);
  const navigate = useNavigate();  
  const columns = [
    {
      title: "Гарчиг",
      dataIndex: "title",
      key: "title",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Явц",
      dataIndex: "desc",
      key: "desc",
    },
    {
      title: "Хариу",
      dataIndex: "link",
      key: "action",
      render: (_, record) =>
        record.action == "Done" ? (
          <Tag color="success">Баталгаажсан</Tag>
        ) :
        !record.link?(<Tag color="success">{record.actionText}</Tag>): (
          <Button
            className="justify-center"
            type="primary"
            onClick={() => {
              if (record.link) navigate(record.link);
            }}
          >
            {record.actionText ?? "Хариу"}
          </Button>
        ),
      width: 100,
      fixed: "right",
    },
  ];

  return (
    <Table columns={columns} dataSource={data} pagination={false} />
  );
}

export default StudentStatus;
