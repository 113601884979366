import React, { useEffect, useState, useContext } from "react";
import { Button, Image, Flex, Spin, Checkbox } from "antd";
import { useWindowSize } from "react-use";

import { instance } from "utils/axios";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "contexts";
import { useParams } from "react-router-dom";
export default function Qpay() {
  const getBase64Img = (item) => {
    return `data:image/pngs;base64,${item}`;
  };
  const { width, height } = useWindowSize();
   const { state } = useContext(AuthContext);
   const name = state.studentInfo?.profile?.firstName;

   const navigate = useNavigate();
   const [data, setData] = useState(null);
   const [loading, setLoading] = useState(true);
   const [approve, setApprove] = useState(false);
   const [status, setStatus] = useState(null);
   const [qpayData, setQpayData] = useState(null);
   let { guid } = useParams();
      
   useEffect(() => {
    fetchData()
   }, []);
   const fetchData=()=>{
     setLoading(true);
     instance({
       method: "get",
       url: `/student/tuition-schedule/${guid}`,
     })
       .then((res) => {
         setData(res.data);
         let json = JSON.parse(res.data.qpayData);
         setStatus(res.data.status);

         setQpayData(json);
       })
       .catch((err) => {
         console.log(err);
       })
       .then(() => setLoading(false));
   }
  return loading ? (
    <div className="flex flex-col items-center mt-20">
      <Flex align="center" gap="middle">
        <Spin size="large" />
      </Flex>
    </div>
  ) :!data ? (
          <div className=" gap-4 mx-auto py-10 md:py-0 md:mb-8">
            Мэдээлэл олдсонгүй
          </div>
        ) : !approve && status != "Success" && data.type == "SeatConfirm" ? (
    <div className="flex flex-col items-center h-screen relative">
      <div className="mt-20  text-[16pt] md:text-[20pt] xl:text-[30pt] font-bold text-primary leading-[1.38]">
        Суудал баталгаажуулах нөхцөл
      </div>
      <div className="mt-10 w-full md:w-2/5 flex justify-center  p-5  border-2 border-primary">
      
        {/* <br /> */}
        <br /> Та 2024-2025, Томүжин Алтернатив сургуульд
        суралцах суудлаа доорх линкээр 2,000,000 төгрөг байршуулж баталгаажуулна
        уу.
        <br />
        <br /> Энэхүү суудал баталгаажуулалтын төлбөр нь таны сургалтын
        төлбөрийн дүнд тооцогдох тул сургалтын төлбөрөөсөө 2,000,000 төгрөг төлж
        байна гэж ойлгоорой. <br />
        <br />
        Суудлаа баталгаажуулж 2,000,000 төгрөгөө төлсний дараа суралцах
        шийдвэрээ өөрчилвөл тус суудал баталгаажуулалтын төлбөр нь БУЦААГДАХГҮЙ
        гэдгийг анхаарна уу.
      </div>
    
        <div className="mt-10 w-full flex justify-center pb-10">
          <Checkbox
            value={approve}
            onChange={() => {
              setApprove(true);
            }}
          />
          <div className="ml-10">
            Би дээрх суудал баталгаажуулалтын нөхцлийг уншиж танилцсан, зөвшөөрч
            байна.
          </div>
        </div>
   
    </div>
  ) : (
    <div className="grid grid-cols-12 h-full gap-2 font-raleway justify-center ">
      <div className="col-span-12  p-4  flex  justify-center text-[20pt] md:text-[20pt] font-bold  text-primary leading-[1.38]">
        Сургалтын төлбөр
      </div>

      <div className="md:col-start-4 col-span-12 md:col-span-3 shadow p-4 rounded-lg h-60 bg-slate-100">
        <div className=" border-primary p-2 italic font-bold">Нэр:</div>
        <div className=" border-primary p-2 italic">{data.name}</div>
        <div className=" border-primary p-2 italic font-bold">Төрөл:</div>
        <div className="border-primary p-2 italic">{data?.title}</div>
        <div className="border-t-2 text-right border-primary p-2 italic font-bold">
          Төлөх дүн: ₮{Number(data.amount).toLocaleString()}
        </div>
      </div>
      <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-slate-100">
        {status == "Success" ? (
          <div className="col-span-12 mt-16 p-4  flex  justify-center text-[20pt] md:text-[20pt] font-bold  text-primary leading-[1.38]">
            Төлөгдсөн байна
          </div>
        ) : (
          <div className="w-full  md:flex justify-center flex-wrap">
            <div className="mt-2 w-full  md:flex justify-center ">
              <img
                src={getBase64Img(qpayData.qr_image)}
                className="w-full  md:w-3/5 "
              ></img>
            </div>
            <Button
              type="primary"
              className="mt-2 w-full md:w-40"
              onClick={() => {
                fetchData();
              }}
            >
              Гүйлгээ шалгах
            </Button>
            <div className="mt-4 md:hidden text-[16pt] flex justify-center  font-bold text-primary leading-[1.38]">
              Банкны аппликейшн
            </div>
            <div className="mt-8 w-full flex justify-center md:hidden ">
              <div className="grid grid-cols-12 gap-2">
                {qpayData.urls.map((e, i) => {
                  return (
                    <div
                      w="100%"
                      key={i}
                      onClick={() => {
                        window.open(e.link);
                      }}
                      p={2}
                      className="col-span-4 md:col-span-2 shadow p-4 rounded-lg flex items-center gap-6"
                    >
                      <img
                        borderRadius="full"
                        w="50px"
                        h="50px"
                        alt="avatar"
                        src={e.logo}
                        style={{ height: 50 }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
