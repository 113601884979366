import React, { useEffect } from 'react'
import logo from 'assets/images/logo.jpg'
import { FaUser } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

function Header() {
  useEffect(() => {
    const onScroll = (e) => {

      if (window.scrollY > 20) {
        document
          .getElementById("header")
          .classList.replace("bg-transparent", "bg-primary2");
      } else {
        document
          .getElementById("header")
          .classList.replace("bg-primary2", "bg-transparent");
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div id='header' className={'fixed inset-x-0 h-[60px] top-0 flex justify-between px-7 py-2 bg-transparent z-50 transition-all'}>
      <img src={logo} style={{height: 40}}/>
    </div>
  );
}

export default Header;
