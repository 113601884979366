import React, { useEffect, useState } from "react";
// import home from 'assets/images/home.jpg'

import { Button, Checkbox, Flex,Spin } from "antd";
import { useWindowSize } from "react-use";

import { instance } from "utils/axios";
export default function SeatConfirm() {
  const [approve, setApprove] = useState(false);
  const [feeStatus, setFeeStatus] = useState(null);
  const [qpayData, setQpayData] = useState(null);
  
  const [loading, setLoading] = useState(false);
  const { width, height } = useWindowSize();

  useEffect(() => {
    setLoading(true);
    instance({
      method: "get",
      url: `/applicant/seatconfirm`,
    })
      .then((res) => {
       
        let json = JSON.parse(res.data.qpayData);
        setFeeStatus(res.data.feeStatus);
        
        setQpayData(json);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  }, []);
  const getBase64Img = (item) => {
    return `data:image/pngs;base64,${item}`;
  };
  
  // const fetchData = async () => {
  //   // phone == "" ? setIsError(true) : setIsError(false);
  //   setLoader(true);

  //   await axios
  //     .get(`https://apituition.tomujin.org/api/summerschool/transaction/${id}`)
  //     .then(async (response) => {
  //       setTransactionId(id);
  //       setProcess(33);
  //       if (response.data.feeStatus === "Success") {
  //         setIsUsed(true);
  //       } else {
  //         let json = JSON.parse(response.data.qpayData);
  //         setQpay(json.urls);
  //         let base64img = getBase64Img(json.qr_image);
  //         setQrImage(base64img);
  //       }
  //       setLoader(false);
  //     })
  //     .catch((err) => {
  //       if (err.code === "ERR_BAD_REQUEST") {

  //       }
  //       console.log(err);
  //       setLoader(false);
  //     });

  //   setLoader(false);
  // };
  return (
    <div>
      {loading ? (
        <div className="flex flex-col items-center mt-20">
          <Flex align="center" gap="middle">
            <Spin size="large" />
          </Flex>
        </div>
      ) : (
        <div className="flex flex-col items-center h-screen relative">
          {!approve && feeStatus != "Success" ? (
            <>
              <div className="mt-20  text-[16pt] md:text-[20pt] xl:text-[30pt] font-bold text-primary leading-[1.38]">
                Суудал баталгаажуулах нөхцөл
              </div>
              <div className="mt-10 w-full md:w-2/5 flex justify-center  p-5  border-2 border-primary">
                Томүжин Алтернатив сургуулийн элсэлтийн үе шатуудыг амжилттай
                давж энэ хүртэл ирсэнд нь дахин баяр хүргэе!
                <br />
                <br /> Та элсэлтийн хариугаа баталгаажуулж, Томүжин Алтернатив
                сургуульд суралцах суудлаа доорх линкээр 1,000,000 төгрөг
                байршуулж баталгаажуулна уу.
                <br />
                <br /> Энэхүү суудал баталгаажуулалтын төлбөр нь таны сургалтын
                төлбөрийн дүнд тооцогдох тул сургалтын төлбөрөөсөө 1,000,000
                төгрөг төлж байна гэж ойлгоорой. <br />
                <br />
                Суудлаа баталгаажуулж 1,000,000 төгрөгөө төлсний дараа суралцах
                шийдвэрээ өөрчилвөл тус суудал баталгаажуулалтын төлбөр нь
                БУЦААГДАХГҮЙ гэдгийг анхаарна уу.
              </div>
              <div className="mt-10 w-full flex justify-center">
                <Checkbox
                  value={approve}
                  onChange={() => {
                    setApprove(true);
                  }}
                />
                <div className="ml-10">
                  Би дээрх суудал баталгаажуулалтын нөхцлийг уншиж танилцсан,
                  зөвшөөрч байна.
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mt-16  text-[20pt] md:text-[20pt] xl:text-[30pt] font-extrabold text-primary leading-[1.38]">
                Суудал баталгаажуулалт
              </div>
              {feeStatus == "Success" ? (
                <div className="mt-16  text-[16pt] leading-[1.38]">
                  Баталгаажсан байна.
                </div>
              ) : (
                <>
                  <div className="mt-2 w-full  md:flex justify-center">
                    <img
                      src={getBase64Img(qpayData.qr_image)}
                      className="w-full  md:w-1/5 "
                    ></img>
                  </div>
                  <Button type="primary" className="w-4/5 md:w-40">
                    Гүйлгээ шалгах
                  </Button>
                  <div className="mt-2  md:hidden text-[20pt] md:text-[20pt] xl:text-[30pt] font-bold text-primary leading-[1.38]">
                    Банкны аппликейшн
                  </div>
                  <div className="mt-16 w-full flex justify-center md:hidden ">
                    <div className="grid grid-cols-12 gap-2">
                      {qpayData.urls.map((e, i) => {
                        return (
                          <div
                            w="100%"
                            key={i}
                            onClick={() => {
                              window.open(e.link);
                              // setProcess(100);
                            }}
                            p={2}
                            className="col-span-4 md:col-span-2 shadow p-4 rounded-lg flex items-center gap-6"
                          >
                            <img
                              borderRadius="full"
                              w="50px"
                              h="50px"
                              alt="avatar"
                              src={e.logo}
                              style={{ height: 80 }}
                            />
                            {/* <span fontSize={"8px"} >
                    {e.name}
                  </span> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
