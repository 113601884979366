import React, { useEffect, useContext,useState } from "react";
import home from "assets/images/home.png";
import vertical from "assets/images/vertical.png";
import { Header } from "components";
import { Button } from "antd";
import { instance } from "utils/axios";
import { useWindowSize } from "react-use";
import ReactConfetti from "react-confetti";
import { useNavigate } from "react-router-dom";
import logo from "assets/images/logo.jpg";
import { AuthContext } from "contexts";
function Decision() {
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const { state } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const name = state.studentInfo?.profile?.firstName;
  useEffect(() => {
    setLoading(true);
    instance({
      method: "get",
      url: `/applicant/decision/view`,
    })
      .then((res) => {
        
   
        // setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  }, []);
  return (
    <div className="relative">
      <Header />
      <div
        id="home-section"
        className="w-full sm:h-screen flex justify-center items-center overflow-x-hidden bg-scroll sm:bg-fixed"
        style={{
          background: `url(${width < height ? vertical : home})`,
          backgroundPosition: "center",
          height: width < height ? "45vh" : "100vh",
          backgroundSize: "cover",
        }}
      >
        {/* <img src={vertical} className="sm:hidden block" /> */}
        <ReactConfetti
          gravity={0.2}
          style={{ width: "100%", height: "100vh" }}
        />
        <div className="max-w-[1280px] px-10 sm:px-24 xl:px-0 text-white sm:block ">
          <h1 className="text-[24pt] sm:text-[40pt] md:text-[60pt] xl:text-[80pt] font-extrabold leading-none drop-shadow">
            БАЯР ХҮРГЭЕ!
          </h1>
          <div className="mt-[34pt] text-[20pt] md:text-[35pt] xl:text-[50pt] leading-[1.38] drop-shadow">
            Ta Томүжин Алтернатив сургуульд ТЭНЦЛЭЭ!
          </div>
          {/* <div className="text-[16pt] md:text-[20pt] drop-shadow mt-5">
            Congratulations. You are 90% there!{" "}
          </div> */}
        </div>
      </div>
      {/* ---------   Mobile Text   ----------- */}
      {/* <div className="max-w-[1280px] px-10 sm:px-24 xl:px-0 text-primary sm:hidden block py-10">
        <h1 className="text-[24pt] sm:text-[40pt] md:text-[60pt] xl:text-[80pt] font-extrabold leading-none drop-shadow">
          It's a YES!
        </h1>
        <div className="mt-[34pt] text-[20pt] md:text-[35pt] xl:text-[50pt] leading-[1.38] drop-shadow">
          You are ACCEPTED to continue to the next stage.
        </div>
        <div className="text-[16pt] md:text-[20pt] drop-shadow mt-5">
          Congratulations. You are 90% there!{" "}
        </div>
      </div> */}
      <div className="w-full min-h-screen flex justify-center items-center">
        <div className="max-w-[1280px]">
          <div className="w-2/3 flex flex-col gap-4 mx-auto py-20 md:py-0 font-sans">
            <div className="text-[20pt] md:text-[40pt] xl:text-[50pt] font-extrabold text-primary leading-[1.38]">
              Хүндэт {name},
            </div>

            <div className="mt-10 font-sans">
              Юуны түрүүнд энэ хүртэл өөрийгөө илэрхийлж, сорьж, өөртөө итгэх
              итгэл, хүсэл мөрөөдөл, үнэ цэнээ илэрхийлж, баталж харуулж чадсанд
              тань томоос том БАЯР ХҮРГЭЕ! Toмүжин Алтернатив сургуулийн сурагч
              болсноор та Монгол улсын өнцөг булан бүрээс асар өндөр
              өрсөлдөөнтэй бүрэлдсэн хүрээллийн нэг хэсэг болж, олон зуун жил
              шинэчлэгдээгүй хэвшмэл боловсролыг халж #Алтернатив сургалтын арга
              технологийг эрэлхийлэх бидний энэхүү аялалд нэгдэж буй цөөхөн
              хэдэн сурагчдын нэг болж байгаа юм.
            </div>
            <div>
              Toмүжин Алтернатив сургуульд та улам ихээр өөрийгөө сорьж,
              хүрээллийн гайхамшгийг мэдрэхийн сацуу олон төрлийн төсөл
              хэрэгжүүлснээр ахлах сургуулийнхаа дараах амьдралдаа бүрэн
              бэлтгэгдэнэ гэдэгт итгэлтэй байна. Бидний тэргүүн зорилго өөр
              бусад зүйлсээс илүү сурагч танд хэрэгцээтэй дэмжлэгийг үзүүлэх
              байж, таныг хүссэн үр дүнд чинь хүргэхийн төлөө бүхнээ зориулах
              болно. Томүжин Алтернатив сургуулийг төгсөөд та манлайлагч, шинийг
              санаачлагч, сэтгэгч, бүтээгчийн хувьд хүрээлэн буй орчин, хамт
              олондоо эерэг нөлөө үзүүлж, гэгээлэг ирээдүйн төлөө хамтдаа
              зүтгэнэ гэж бид найдаж байна.
            </div>
            <div>
              Томүжингийн нийт хамт олны нэрийн өмнөөс Toмүжин Алтернатив
              сургуулийн хүрээллийн онцгой нэгэн бүрэлдэхүүн болсонд дахин баяр
              хүргэе!
            </div>
            <br />
            <div>
              Хүндэтгэсэн, <br />
              Томүжин Алтернатив сургуулийн элсэлтийн баг
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[60vh] bg-primary2 flex justify-center items-center">
        <div className="mx-auto max-w-[1280px] px-12 leading-[1.38]">
          <div className="text-[25pt] md:text-[30pt] xl:text-[30pt] text-white mb-12">
            Элсэлтийн үе шатуудын явцын мэдээлэл рүүгээ буцаж орж дараагийн үе
            шатуудтайгаа танилцаарай.
          </div>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              // window.open("/", "_blank", "noopener,noreferrer");
              navigate("/");
            }}
          >
            Буцах
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Decision;
