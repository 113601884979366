import React, { useEffect, useState, useContext } from "react";
import { instance } from "utils/axios";
import { Table, Spin, Button, Tag, Modal } from "antd";
import QRCode from "qrcode.react";
import { AuthContext } from "contexts";
import QuestionForm from "../QuistionForm"; 
import { useNavigate } from "react-router-dom";
function StudentDay() {
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);
  const student = state.studentInfo?.profile;
  const [attendance, setAttendance] = useState([]);
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [quiz, setQuiz] = useState(null);
  const [modalVisible, setModalVisible] = useState(false); 

  useEffect(() => {
    fetchData(); 
  }, []);
 const fetchQuizDetails = () => {
  
    instance({
      method: "get",
      url: `/student/daily/quiz`,
    })
      .then((res) => {
        if(res.data){
          setQuiz(res.data);
          setModalVisible(true);
        }else{
         
        Modal.info({
          title: "Анхааруулга",
          content: "Өнөөдрийн асуулгаа бөглөсөн байна.",
        });
          navigate("/feed");
        }
      })
      .catch((error) => {
        console.error("Failed to fetch quiz details", error);
      })
     
  };
  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/student/attendance`,
    })
      .then((res) => {
        setAttendance(res.data);
      })
      .catch((err) => {
        console.log("here");
      })
      .then(() => setLoading(false));
    instance({
      method: "get",
      url: `/student/profile`,
    })
      .then((res) => {
        console.log(res.data);
        setProfile(res.data);
      })
      .catch((err) => {
        console.log("here");
      })
      .then(() => setLoading(false));
  };

  const getStudentInfo = () => {
    if (!student) return {};
    const lastNameInitial = student.lastName
      ? student.lastName.charAt(0) + "."
      : "";
    const firstName = student.firstName || "";
    const code = student.code || "Unknown";
    return { lastNameInitial, firstName, code };
  };
  const { lastNameInitial, firstName, code } = getStudentInfo();

  const handleQuestionSubmit = (values) => {
     setLoading(true);
     instance({
       method: "post",
       url: "student/daily/quiz",
       data: values,
     })
       .then((res) => {
       navigate("/feed");
       })
       .catch((err) => {})
       .finally(() => {
         setLoading(false);
       });
  };

  const columns = [
    { title: "Огноо", dataIndex: "attendanceTime", key: "date" },
    {
      title: "Төлөв",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color;
        switch (status) {
          case "Ирсэн":
            color = "green";
            break;
          case "Хоцорсон":
            color = "warning";
            break;
          case "Чөлөөтэй":
            color = "blue";
            break;
          case "Тасалсан":
            color = "red";
            break;
          default:
            color = "default";
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
    // {
    //   title: "Асуулга",
    //   dataIndex: "dailyQuiz",
    //   key: "dailyQuiz",
    //   render: (value) => {
    //     return <Tag color={value ? "success" : "success"}>{value}</Tag>;
    //   },
    // },
  ];

  return (
    <div className="student-home-container flex flex-col items-center h-screen relative">
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="mt-4 w-full flex justify-center">
          <div className="m-8 w-full max-w-[600px]">
            <div className="student-info text-center bg-white p-4 rounded-lg shadow-lg">
              <div className="flex items-center justify-center space-x-4">
                <div className="bg-primary p-2">
                  <img
                    src={profile?.house?.googleLink}
                    alt="Profile"
                    className="h-8 w-8 object-cover rounded-full"
                  />
                </div>
                <div>
                  <h3 className="text-2xl font-bold inline-block">
                    {lastNameInitial} {firstName}
                  </h3>
                  <p className="text-lg font-semibold">id: {code}</p>
                </div>
              </div>

              <div className="my-4 flex justify-center">
                <QRCode
                  value={student?.code?.toString() || "25304"}
                  size={150}
                  level={"H"}
                />
              </div>
            </div>

            <div className="my-4 flex justify-center gap-2">
              <Button onClick={fetchData}>Refresh</Button>
              <Button type="primary" onClick={() => fetchQuizDetails()}>
                Асуулга
              </Button>
            </div>

            <div className="attendance-list bg-white p-4 rounded-lg shadow-lg mt-4">
              <h3 className="text-xl font-semibold mb-4">Ирц</h3>
              <Table
                dataSource={attendance}
                columns={columns}
                rowKey="id"
                pagination={false}
                className="w-full"
              />
            </div>
          </div>
        </div>
      )}

      {/* Modal for the Question Form */}
      <QuestionForm
        visible={modalVisible}
        quiz={quiz}
        loading={loading}
        onClose={() => setModalVisible(false)}
        onSubmit={handleQuestionSubmit}
      />
    </div>
  );
}

export default StudentDay;
