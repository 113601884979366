import React, { useEffect, useState, useContext, useRef } from "react";

import { Button, Space, List, Table, Tag } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useWindowSize } from "react-use";
// import axios from "axios";
import { instance } from "utils/axios";
import { useNavigate } from "react-router-dom";
import logo from "assets/images/logo.jpg";
import { AuthContext } from "contexts";
import ApplicantHome from "./ApplicantHome";
import ParentHome from "./ParentHome";
import StudentDay from "./StudentDay";

function Home() {
  const { state } = useContext(AuthContext);
  const roleId = state.studentInfo?.profile?.roleId;
  return  roleId == "5" ? (
    <ApplicantHome />
  ) : roleId == "6" ? (
    <ParentHome />
  ) : roleId == "4" ? (
    <StudentDay/>
  ) : (
    <div></div>
  );
}

export default Home;
