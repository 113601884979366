import React from 'react'

function Page404() {
  return (
    <div className='flex items-center justify-center'>
      Хуудас олдсонгүй
    </div>
  )
}

export default Page404