import { ConfigProvider } from "antd"

function StyleConfigProvider ({children}) {
  return(
    <ConfigProvider 
      theme={{
        token: {
          colorPrimary: '#5c1090',
          // colorBgContainer: '#f6ffed',
        },
      }}
    >
      {children}
    </ConfigProvider>
  )
  
}

export default StyleConfigProvider