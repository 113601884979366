import { createBrowserRouter } from "react-router-dom";
import routes from "./routes";
import { Login, Qpay } from "pages";
import ProtectRoute from "./ProtectRoute";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: (
      <div className="h-screen flex items-center justify-center text-xl font-bold italic">
        ERROR
      </div>
    ),
    element: <ProtectRoute />,
    children: [...routes].map((foo) => {
      return {
        ...foo,
      };
    }),
  },
  {
    path: "/login",
    errorElement: <div>error</div>,
    element: <Login />,
  },
  // { name: "Qpay", path: "payment/qpay/:guid", element: <Qpay /> },
]);

export default router;
