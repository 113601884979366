import React, { useContext, useEffect, useRef, useState } from "react";
import { Checkbox, Divider, Form, Input, Button, notification } from "antd";
import { FaRegEnvelope } from "react-icons/fa6";
import { AiOutlineLock } from "react-icons/ai";
import ls from "utils/ls";
import { instance } from "utils/axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "contexts";
import logo from "assets/images/logo.jpg";
import sectionImg from "assets/images/login.webp";
function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const origin = location.state?.from?.pathname || "/";
  const { action, state } = useContext(AuthContext);

  const navigateRoute = () => {
    navigate(origin);
  };

  const handleSubmit = (values) => {
    if (values.rememberMe) {
      ls.set("remember", values);
    } else {
      ls.remove("remember");
    }
    setLoading(true);
    instance({
      method: "post",
      url: "applicant/login",
      data: values,
    })
      .then((res) => {
        action.login(res.data.token, res.data);
        navigateRoute();
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  if (state.token) {
    navigate("/");
  }
  return (
    <div className="flex w-screen h-screen">
      <div
        className="flex-1 relative hidden md:flex justify-center items-center"
        style={{
          background: `url(${sectionImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div className="w-full md:w-1/2 xl:w-2/5 flex flex-col justify-between">
        <div className="flex justify-end px-8 py-6">
          <img src={logo} width={150} />
        </div>
        <div className="flex flex-col self-center md:w-2/3 xl:w-1/2">
          <div className="text-center text-4xl">Тавтай морилно уу!</div>
          <Divider orientation="center">Нэвтрэх</Divider>
          <Form
            size="large"
            onFinish={handleSubmit}
            // initialValues={loginValues}
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: "И-Майл хаяг оруулна уу" }]}
            >
              <Input prefix={<FaRegEnvelope />} />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message:
                    "Та үсэг болон тэмдэгт оруулахгүй 6 оронтой тоо оруулна уу",
                },
              ]}
            >
              <Input.Password prefix={<AiOutlineLock />} />
            </Form.Item>
            {/* <div className='flex justify-between'>
              <Form.Item name='rememberMe' valuePropName="checked">
                <Checkbox>Намайг сана</Checkbox>
              </Form.Item>
              <Form.Item>
                <button className='text-primary hover:underline'>Нууц үгээ мартсан</button>
              </Form.Item>
            </div> */}
            <Form.Item>
              <Button
                className="w-full"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Нэвтрэх
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="self-center text-secondary text-xs p-2">
          © 2024 Tomujin School. Зохиогчийн эрх хамгаалагдсан
        </div>
      </div>
    </div>
  );
}

export default Login;
