import React, { useEffect, useState, useContext, useRef } from "react";

import { Button, Space, List, Table, Tag ,Flex,Spin} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useWindowSize } from "react-use";
import moment from "moment";
import { instance } from "utils/axios";
import { useNavigate } from "react-router-dom";
import logo from "assets/images/logo.jpg";
import { AuthContext } from "contexts";
import { useParams } from "react-router-dom";

function StudentPayment() {
  const { state } = useContext(AuthContext);
  const name = state.studentInfo?.profile?.firstName;
 
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  let { id } = useParams();
  useEffect(() => {
    setLoading(true);
    instance({
      method: "get",
      url: `/guardian/student/tuition/${id}`,
    })
      .then((res) => {

        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  }, []);
  return (
    <div className="flex flex-col items-center h-screen relative font-raleway font-raleway">
      <div className="md:mt-16 w-full flex justify-center">
        {loading ? (
          <div className="flex flex-col items-center mt-20">
            <Flex align="center" gap="middle">
              <Spin size="large" />
            </Flex>
          </div>
        ) : !data ? (
          <div className=" gap-4 mx-auto py-10 md:py-0 md:mb-8">
            Мэдээлэл олдсонгүй
          </div>
        ) : (
          <div className="m-8 md:w-4/5 w-full">
            <div className=" gap-4 mx-auto py-10 md:py-0 md:mb-8">
              <div className="text-[20pt] xl:text-[30pt] font-extrabold text-primary leading-[1.38]">
                Сайн байна уу {name},
              </div>
              <div className="mb-8  mt-8 leading-[1.38] ">
                {/* Томүжин Алтернатив сургуулийн урилга, ѳргѳдѳл, бататгах сорил
                гээд 3 үе шатыг амжилттай давж тэнцсэнд нь дахин баяр хүргэе!
                <br /> */}
                <br /> Дараах хүснэгтээс сургалтын тѳлбѳр, санхүүгийн дэмжлэгийн
                мэдээллийг авна уу.
              </div>
              <div className="font-bold text-primary">Сургалтын төлбөр</div>
              <div className="border-t-2 border-primary p-2 italic">
                <b style={{ color: "red" }}> *</b> Сургалтын төлбөр нь тухайн
                хичээлийн жилд сурагчид зориулсан сургалтын нийт үйл ажиллагааны
                төлбөр юм. Үүнд сурагчийн зорчих унааны төлбөр, өдрийн хоол,
                сурах бичиг болон хичээлээс гадуурх үйл ажиллагааны төлбөр
                багтаагүй болно.
              </div>
              <div className="mt-1 border-t-4 text-primary border-b-4 p-2 flex justify-between border-primary text-right">
                <div className="font-bold text-primary text-left">
                  Нийт төлбөр:
                </div>{" "}
                <div className="font-bold text-primary text-rigth">
                  ₮{Number(data?.tuitionAmount ?? 0).toLocaleString()}
                </div>
              </div>
              {data?.finAid > 0 ? (
                <div>
                  <div className="font-bold text-primary mt-14">
                    Санхүүгийн дэмжлэг
                  </div>
                  <div className="border-t-2 border-primary p-2 italic">
                    <b style={{ color: "red" }}> *</b>Санхүүгийн дэмжлэг нь таны
                    хүүхдэд Томүжин Алтернатив сургуулиас сургалтын төлбөрѳѳ
                    төлөхөд олгож буй санхүүжилтийн хэлбэр ба буцалтгүй тусламж
                    юм.
                  </div>
                  <div className=" border-t-4 border-primary p-2 flex justify-between">
                    <div className="text-left">Санхүүгийн дэмжлэгийн хувь</div>
                    <div className="text-right">
                      {data?.finAidPercent ?? 0}%
                    </div>
                  </div>
                  <div className=" border-b-4 border-primary p-2 flex justify-between text-primary font-bold">
                    <div className="text-left">
                      Таны хүүхдэд олгож буй санхүүгийн дэмжлэг
                    </div>
                    <div className="text-right">
                      {" "}
                      ₮{Number(data?.finAid ?? 0).toLocaleString()}
                    </div>
                  </div>
                  {/* <div className="mt-1 text-right">
              <b>Нийт олгож буй санхүүгийн дэмжлэг:</b> ₮2,990,000
            </div> */}
                  <div className="font-bold text-primary mt-14">
                    Таны төлөх төлбөр
                  </div>

                  <div className="border-t-2 border-primary p-2 italic">
                    <b style={{ color: "red" }}> * </b> Төлөх нийт төлбөр нь
                    нийт төлбөр болон нийт санхүүгийн дэмжлэгийн ялгавар юм.
                  </div>
                  <div className="mt-1 border-t-4 text-primary border-b-4 p-2 flex justify-between border-primary text-right">
                    <div className="font-bold text-primary text-left">
                      Таны тѳлѳх нийт тѳлбѳр
                    </div>{" "}
                    <div className="font-bold text-primary text-rigth">
                      {" "}
                      ₮
                      {Number(
                        data?.tuitionAmount - data?.finAid ?? 0
                      ).toLocaleString()}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="font-bold text-primary mt-14">
                Төлбөрийн хуваарь
              </div>
              <div className="border-t-2 border-primary p-2 italic">
                <b style={{ color: "red" }}> *</b>Таны төлөх төлбөр нь дараах
                хуваарийн дагуу тѳлѳгдсѳн байх шаардлагатай.
              </div>
            </div>
            <div className=" border-b-4 border-primary p-2 flex justify-between font-bold">
              <div className="text-left">Төлбөр тѳлѳх ээлж</div>
              <div className="text-center">Тѳлѳх эцсийн хугацаа</div>
              <div className="text-center">Тѳлѳх хэлбэр</div>
              <div className="text-right">Тѳлѳх дүн</div>
            </div>
            {/* <div className="p-2 flex justify-between text-[10pt] md:text-[12pt] "> */}
            {data?.tuition_schedules?.map((el) => {
              return (
                <>
                  <div className="flex p-2  justify-between text-[10pt] md:text-[12pt]">
                    <div className="text-left  w-60">
                      {el.title ?? "Төлбөр"}
                    </div>
                    <div className="text-center">
                      {moment(el.payDate).format("YYYY оны M сарын DD")}
                    </div>
                    <div className="text-center">{el.paymentType}</div>
                    <div className="text-right">
                      ₮ {Number(el.amount ?? 0).toLocaleString()}{" "}
                      {el.status == "PAID" ? (
                        <Tag color="success">Төлсөн</Tag>
                      ) : (
                        <Button
                          style={{
                            backgroundColor: "#f2cd30",
                            color: "#5c1090",
                          }}
                          onClick={() => {
                            window.open(`/student/qpay/${el.guid}`, "_blank");
                            // navigate("/decision");
                          }}
                        >
                          Төлөх
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              );
            })}

            {/* </div> */}
            <div className="p-2 flex justify-between text-[10pt] md:text-[12pt] border-primary  border-b-4 "></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default StudentPayment;
